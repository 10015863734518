import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { ErrorModalProvider } from 'context/error-modal-provider';
import BridgePage from 'pages/bridge-page';
import BridgeTestPage from 'pages/bridge-page/bridge-test-page';
import { CSPage, CSRequestForm } from 'pages/cs-page';
import { DetailPage } from 'pages/detail-page';
import { MainPage, MainTestPage } from 'pages/main-page';
import { NotFoundPage } from 'pages/not-found-page';

function App() {
  return (
    <ErrorModalProvider>
      <Router>
        <Routes>
          <Route path="/SDK/detail" element={<DetailPage />} />
          <Route path="/cs" element={<CSPage />} />
          <Route path="/cs-request" element={<CSRequestForm />} />
          <Route path="/SDK/list" element={<MainPage />} />
          <Route path="/bridge" element={<BridgePage />} />
          <Route path="/testBridge" element={<BridgeTestPage />} />
          <Route path="/testPage" element={<MainTestPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ErrorModalProvider>
  );
}

export default App;
