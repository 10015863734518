import { memo, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getCPMCampaignList, getCampaignList, getRVInfoList } from 'apis';
import NoAd from 'assets/no-ad.svg';
import {
  NoAdModalState,
  RVInfoListState,
  blockedCampaignList,
  campaignListState,
  choiceOfferCampaignState,
  completedRcpcList,
  cpmCampaignListState,
  currencyState,
  isTestState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
  selectedCampaignState,
  selectedTabState,
  tabListState,
  totalRewardState,
} from 'atoms/campaignState';
import { Modal, RcpcAd } from 'components';
import { useErrorModal } from 'context';
import { ConvertCampaignFormat, convertCampaignParameter, isToday } from 'utils';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

import { cashNoteHeight } from './constants';

export function CampaignList({ checkInstalledPackageList, checkOrJoinCampaign }) {
  const { openModal, closeModal } = useErrorModal();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const listRef = useRef(null);
  const [, setShowChoiceOffer] = useState(false);
  const [, setShowTimeoutModal] = useState(false);

  const isRewardVideoIntegrated = queryParams.get('isRewardVideoIntegrated') === 'true';

  const [blockedCampaignKeys, setBlockedCampaignKeys] = useRecoilState(blockedCampaignList);

  const [show501Error, setShow501Error] = useState(false);

  const [isTest, setIsTest] = useRecoilState(isTestState);

  const [campaignList, setCampaignList] = useRecoilState(campaignListState);
  const [rvInfoList, setRVInfoList] = useRecoilState(RVInfoListState);
  const [cpmCampaignList, setCpmCampaignList] = useRecoilState(cpmCampaignListState);

  const selectedCampaign = useRecoilValue(selectedCampaignState);
  const [showNoAdModal, setShowNodAdModal] = useRecoilState(NoAdModalState);

  const [currency, setCurrency] = useRecoilState(currencyState);

  const mediaKey = useRecoilValue(mediaKeyState);
  const parameter = useRecoilValue(parameterState);
  const platform = useRecoilValue(platformState);
  const tabList = useRecoilValue(tabListState);
  const selectedTab = useRecoilValue(selectedTabState);
  const isViewMode = useRecoilValue(isViewModeState);

  const setRcpcCampaign = useSetRecoilState(completedRcpcList);
  const setTotalReward = useSetRecoilState(totalRewardState);
  const setChoiceOffer = useSetRecoilState(choiceOfferCampaignState);

  const [modalContent, setModalContent] = useState(null);

  const installedCampaignList = localStorage.getItem('installedCampaigns')
    ? JSON.parse(localStorage.getItem('installedCampaigns'))
    : [];

  const [installedCampaignKeys, setInstalledCampaignKeys] = useState(installedCampaignList);

  function filterObjectByCondition(obj, condition) {
    const filteredObject = {};

    // 객체의 모든 키에 대해 순회
    for (const key in obj) {
      // 객체의 속성인지 확인하고, 조건을 만족하는지 확인
      if (Object.hasOwnProperty.call(obj, key) && condition(obj[key])) {
        filteredObject[key] = obj[key]; // 조건을 만족하는 속성을 새 객체에 추가
      }
    }

    return filteredObject;
  }

  const newObj = localStorage.getItem('blockedCampaignList')
    ? filterObjectByCondition(JSON.parse(localStorage.getItem('blockedCampaignList')), isToday)
    : {};

  const [blockedCampaigns, setBlockedCampaigns] = useState(newObj);

  useEffect(() => {
    localStorage.setItem('blockedCampaignList', JSON.stringify(blockedCampaigns));
  }, []);

  const CPMTabInfo = tabList?.filter((tab) => tab.IncludeParticipationNo.includes('43'))?.[0];

  const handleScroll = ({ scrollOffset }) => {
    if (selectedTab?.selectedTabId === 2) {
      setShowChoiceOffer(scrollOffset > 160);
    }
  };
  const showVideo = () => {
    sendMessageToSDK('showRewardVideo', null, platform);
  };

  useEffect(() => {
    const nativeResponse = async (event) => {
      const eventResult = event?.detail?.data;

      if (eventResult.EventName === 'campaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setBlockedCampaignKeys((prev) => [...prev, { type: 'campaign', key: newItem?.toString() }]);
      }

      if (eventResult.EventName === 'cpmCampaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setBlockedCampaignKeys((prev) => [...prev, { type: 'content', key: newItem?.toString() }]);
      }

      if (
        eventResult.EventName === 'newsCampaignCompleted' ||
        eventResult.EventName === 'webtoonCampaignCompleted'
      ) {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        const currentDate = new Date();

        const updatedList = { ...blockedCampaigns, [newItem]: currentDate.toISOString() };
        setBlockedCampaigns(updatedList);
        localStorage.setItem('blockedCampaignList', JSON.stringify(updatedList));
      }
      if (eventResult.EventName === 'showDetailPage' && eventResult.Result) {
        if (!selectedCampaign) return;
        if (
          selectedCampaign?.PartiTypeCode === 7 ||
          selectedCampaign?.PartiTypeCode === 23 ||
          selectedCampaign?.PartiTypeCode === 6
        ) {
          const updatedList = selectedCampaign?.Key
            ? [...new Set([...installedCampaignKeys, selectedCampaign?.Key])]
            : installedCampaignKeys;
          setInstalledCampaignKeys(updatedList);
          localStorage.setItem('installedCampaigns', JSON.stringify(updatedList));
        }
        // navigate('/SDK/detail', {
        //   state: {
        //     campaign: selectedCampaign,
        //   },
        // });

        const openURL = isTest
          ? 'https://stage.offerwall.adpopcorn.com'
          : 'https://offerwall.adpopcorn.com';
        sendMessageToSDK(
          'openNewInAppBrowser',
          {
            url: `${openURL}/SDK/detail?auth=${selectedCampaign?.Auth}&isTest=${isTest}&platform=${platform}&mediakey=${mediaKey}`,
          },
          platform
        );
      }

      if (eventResult.EventName === 'showRewardVideo') {
        showVideo();
      }

      if (eventResult.EventName === 'rcpcCampaignCompleted') {
        const scriptId = eventResult.EventParam;
        if (!scriptId) return;
        setRcpcCampaign((prev) => [...prev, scriptId]);
      }

      if (eventResult.EventName === 'rewardVideoCampaignCompleted') {
        if (!isRewardVideoIntegrated) return;
        const rewardVideoPlacementId = eventResult.EventParam;
        const RVParams = {
          mediaKey,
          adid: parameter?.adid || null,
          idfa: parameter?.idfa || null,
          usn: parameter?.usn || null,
        };
        try {
          const rvInfoListData = await getRVInfoList(RVParams, isTest);
          const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];

          const updatedRv = rewardVideoInfo?.find(
            (newRv) => newRv?.IntegrationPlacementId === rewardVideoPlacementId
          );

          // 새로운 배열로 기존 배열 업데이트하기
          const updatedRVInfoList = rvInfoList?.map((rv) => {
            // 새로운 배열에서 IntegrationPlacementId가 일치하는 객체 찾기

            // IntegrationPlacementId가 일치하는 객체를 찾은 경우에만 업데이트
            if (rv?.IntegrationPlacementId === rewardVideoPlacementId) {
              // 기존 객체를 복사하여 업데이트된 정보로 변경
              return {
                ...rv,
                status: 'failed',
                IsAvailable: updatedRv?.IsAvailable,
                AvailableDateTime: updatedRv?.AvailableDateTime,
                CompleteDateTime: updatedRv?.CompleteDateTime,
                // 필요한 경우 다른 필드도 업데이트
              };
            } else {
              // IntegrationPlacementId가 일치하는 객체를 찾지 못한 경우 기존 객체 그대로 반환
              return rv;
            }
          });
          setRVInfoList(updatedRVInfoList);
        } catch (e) {
          openModal(e.message || '');
        }
      }

      if (eventResult.EventName === 'OnRewardVideoAdLoaded') {
        const rewardVideoPlacementId = eventResult.EventParam;
        const floatingRV = rvInfoList?.find(
          (item) =>
            item?.IntegrationPlacementId === rewardVideoPlacementId && item?.PlacementTypeNo === 1
        );

        if (floatingRV) {
          sendMessageToSDK(
            'joinCampaign',
            { jsonParameter: `"${JSON.stringify(convertCampaignParameter(floatingRV))}"` },
            platform
          );
        }

        const newVal = rvInfoList?.map((rv) => {
          if (rv?.IntegrationPlacementId === rewardVideoPlacementId) {
            return { ...rv, status: 'success' };
          }
          return rv;
        });

        setRVInfoList(newVal);
      }

      if (eventResult.EventName === 'OnRewardVideoAdLoadFailed') {
        const rewardVideoPlacementId = eventResult.EventParam;

        const filteredList = rvInfoList?.filter(
          (rv) => rv?.IntegrationPlacementId !== rewardVideoPlacementId
        );

        setRVInfoList(filteredList);
      }

      if (eventResult.EventName === 'installFail') {
        setModalContent({
          ResultMessage: eventResult.ResultMessage || '',
          left: { text: '닫기', func: () => setModalContent(null) },
          right: {
            text: '참여하기',
            func: () => {
              sendMessageToSDK(
                'joinCampaign',
                {
                  jsonParameter: `"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`,
                },
                platform
              );
              setModalContent(null);
            },
          },
        });
      }

      if (eventResult.EventName === 'checkCampaignPackageTargetList' && eventResult.Result) {
        checkOrJoinCampaign(selectedCampaign);
      }
    };

    window.addEventListener('NativeEvent', nativeResponse);

    return () => {
      window.removeEventListener('NativeEvent', nativeResponse);
    };
  }, [selectedCampaign, rvInfoList]);

  useEffect(() => {
    async function fetch() {
      try {
        sendMessageToSDK('viewModeWebViewHeight', { contentsHeight: cashNoteHeight }, platform);
        const campaignListData = await getCampaignList({ ...parameter, app_key: mediaKey });

        //test 모드일 경우 sdk 전달
        sendMessageToSDK('isTestMode', { enable: campaignListData.IsTest }, platform);
        setIsTest(campaignListData.IsTest);

        if (!campaignListData.Result) {
          sendMessageToSDK('onOfferwallLoadFailed', null, platform);
          if (campaignListData?.ResultCode === 501) {
            setShow501Error(true);
            return openModal(
              "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
            );
          }
          return setShowNodAdModal(true);
        }

        const convertedCampaignList = ConvertCampaignFormat(tabList, campaignListData);

        // window?.APRewardOfferwall?.onOfferwallLoadSuccess();
        sendMessageToSDK('onOfferwallLoadSuccess', null, platform);

        setChoiceOffer(
          convertedCampaignList?.[0]?.campaigns?.find((campaign) => campaign?.IsChoiceOffer) || null
        );

        setCampaignList(convertedCampaignList);
        setTotalReward(campaignListData?.TotalRewardQuantity || 0);

        sendMessageToSDK(
          'setTotalReward',
          { totalReward: campaignListData?.TotalRewardQuantity },
          platform
        );

        sendMessageToSDK(
          'setRewardCurrency',
          { currency: campaignListData?.RewardCurrency },
          platform
        );
        setCurrency(campaignListData?.RewardCurrency || null);

        await fetchRVInfoList(campaignListData.IsTest);
      } catch (e) {
        // window?.APRewardOfferwall?.onOfferwallLoadFailed();
        sendMessageToSDK('onOfferwallLoadFailed', null, platform);

        if (e?.code === 501) {
          setShow501Error(true);
          return openModal(
            "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
          );
        } else if (e?.code === 980) {
          return setShowNodAdModal(true);
        } else openModal(e.message || '');
      }
    }

    if (campaignList?.length) return;
    if (!parameter) return;

    fetch();
  }, [parameter]);

  async function fetchRVInfoList(isTest) {
    if (!isRewardVideoIntegrated || platform === 'ios') return;

    const RVParams = {
      mediaKey,
      adid: parameter?.adid || null,
      idfa: parameter?.idfa || null,
      usn: parameter?.usn || null,
    };

    try {
      const rvInfoListData = await getRVInfoList(RVParams, isTest);

      const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];

      setRVInfoList(
        rewardVideoInfo?.map((rv) => {
          return { ...rv, status: 'loading' };
        })
      );

      rewardVideoInfo
        ?.filter?.((rv) => rv?.PlacementTypeNo === 0)
        ?.forEach((rv) => {
          sendMessageToSDK(
            'loadRewardVideo',
            {
              appKey: rv?.IntegrationAppKey || '',
              placementId: rv?.IntegrationPlacementId || '',
              showProgress: false,
            },
            platform
          );
        });
    } catch (e) {
      return;
    }
  }

  const { selectedSubTabData, selectedTabId } = selectedTab;

  const selectedTabInfo = campaignList?.find((tab) => tab.TypeNo === selectedTabId);

  useEffect(() => {
    async function fetch() {
      try {
        if (selectedTabId === CPMTabInfo?.TypeNo) {
          const cpmCampaignListData = await getCPMCampaignList(
            { ...parameter, app_key: mediaKey },
            isTest
          );

          // const cpmCampaignListData = await getCPMCampaignList(mockData, isTest);
          setCpmCampaignList([
            ...(cpmCampaignListData?.CPMCampaigns || []),
            ...(cpmCampaignListData?.RCPCCampaigns || []),
            ...(cpmCampaignListData?.CPCCampaigns || []),
          ]);
        }
      } catch (e) {
        sendMessageToSDK('onOfferwallLoadFailed', null, platform);
        setShowTimeoutModal(true);
        // openModal(e.message || '');
      }
    }

    if (cpmCampaignList.length > 0) return;
    if (!parameter) return;

    fetch();
  }, [selectedTabId, parameter]);

  const selectedCampaigns =
    selectedTabId === CPMTabInfo?.TypeNo
      ? cpmCampaignList
      : selectedTabInfo?.campaigns ||
        selectedTabInfo?.SubTabInfo?.find(
          (subTab) => subTab.TypeNo === selectedSubTabData[selectedTabId]
        )?.campaigns ||
        [];

  const getFilteredSelectedCampaigns = () => {
    const rvListAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 0)?.[0];
    let filteredSelectedCampaigns = (selectedCampaigns || [])
      ?.filter((el) => !Object.keys(blockedCampaigns).includes(el?.Id?.toString()))
      ?.filter(
        (el) =>
          !blockedCampaignKeys.some((blockedCampaign) => {
            return (
              blockedCampaign.key ===
              (blockedCampaign.type === 'campaign' ? el?.Key?.toString() : el?.Id?.toString())
            );
          })
      );

    if (rvListAd && rvListAd?.IsAvailable) {
      const index = selectedTabId === 2 ? rvListAd.DisplayPriority - 1 : 0;
      filteredSelectedCampaigns?.splice(index, 0, rvListAd);
    }

    return filteredSelectedCampaigns;
  };

  const selectedCampaignList = getFilteredSelectedCampaigns();

  useEffect(() => {
    if (listRef?.current) {
      listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
    }
  }, [selectedTab]);

  useEffect(() => {
    listRef.current && listRef.current.resetAfterIndex(0);
  }, [selectedCampaignList]);

  const handleClickRVAd = (rvListAd) => {
    if (rvListAd?.status === 'loading') {
      return openModal('광고가 준비중이에요!\n1분 후 다시 참여해주세요.', closeModal);
    }

    if (rvListAd?.status === 'failed') {
      return sendMessageToSDK(
        'loadRewardVideo',
        {
          appKey: rvListAd?.IntegrationAppKey || '',
          placementId: rvListAd?.IntegrationPlacementId || '',
          showProgress: true,
        },
        platform
      );
    }

    if (rvListAd?.status === 'success') {
      sendMessageToSDK(
        'joinCampaign',
        { jsonParameter: `"${JSON.stringify(convertCampaignParameter(rvListAd))}"` },
        platform
      );
    }
  };

  const currencyString = currency || 'P';

  const Row = memo(
    (props) => {
      const { index, style } = props;
      const campaign = selectedCampaignList?.[index];

      return (
        <div style={style}>
          {campaign?.Key === '958189455' ? (
            <RcpcAd campaign={campaign} />
          ) : campaign?.Key === '559551593' ? (
            <div
              className="px-20 my-16 flex justify-between items-center w-full h-full"
              role="presentation"
              key={index}
              onClick={() => handleClickRVAd(campaign)}
            >
              <img className="rounded-full h-64 max-w-64" alt="비디오 광고" />

              <div className="flex flex-col w-full gap-2 whitespace-nowrap overflow-hidden text-ellipsis py-8 justify-center">
                <div className="text-[16px] leading-[22px] text-[#0C1120] font-normal truncate">
                  영상 보고 포인트 적립하기
                </div>
                <div className="text-[15px] leading-[24px] text-[#2D91EF] font-semibold truncate">
                  {installedCampaignKeys?.includes(campaign.Key) ? (
                    <span className="text-nowrap">설치 확인</span>
                  ) : (
                    <span>{`${campaign?.Point}${currencyString} 적립하기`}</span>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="px-20 py-16 flex justify-between items-center w-full h-full"
              role="presentation"
              key={index}
              onClick={() => {
                let eventName = 'impression_offerwall_detail_page';
                let eventParam = '';

                /** 참여적립의 경우 */
                if (selectedTabId === 2) {
                  eventName = 'impression_offerwall_detail_page_participation';
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 4) {
                  /** 쇼핑 적립의 경우 */
                  eventName = 'impression_offerwall_detail_page_shopping';
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 3) {
                  /** 클릭 적립의 경우 */
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 0) {
                  eventParam = '0';
                } else {
                  const selectedSubTabData = selectedTab?.selectedSubTabData;
                  const selectedTabId = selectedTab?.selectedTabId;
                  eventParam =
                    selectedSubTabData && selectedTabId ? selectedSubTabData[selectedTabId] : '';
                }

                sendMessageToSDK(
                  'logging',
                  {
                    eventName: eventName,
                    eventParam: eventParam,
                  },
                  platform
                );

                checkInstalledPackageList(campaign);
              }}
            >
              <div className="flex w-full h-full gap-16">
                {campaign?.IconImgURL || campaign?.IconUrl ? (
                  <img
                    className="rounded-full h-64 w-64"
                    alt={campaign.Title}
                    src={campaign.IconImgURL || campaign.IconUrl}
                  />
                ) : (
                  <div className="rounded-full h-64 w-64 bg-gray-300 inline-flex" />
                )}
                <div className="flex flex-col w-full gap-2 whitespace-nowrap overflow-hidden text-ellipsis py-8 justify-center">
                  <div className="text-[16px] leading-[22px] text-[#0C1120] font-normal truncate">
                    {campaign?.Title}
                  </div>
                  <div className="text-[15px] leading-[24px] text-[#2D91EF] font-semibold truncate">
                    {installedCampaignKeys?.includes(campaign.Key) ? (
                      <span className="text-nowrap">설치 확인</span>
                    ) : (
                      <span>
                        {campaign?.RewardDetail?.Quantity
                          ? `${campaign?.RewardDetail?.Quantity}${currencyString} 적립하기`
                          : typeof campaign?.Reward === 'string'
                          ? `${campaign?.Reward} 적립하기`
                          : `${campaign?.Reward || 0}${currencyString} 적립하기`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    },
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
  );

  Row.displayName = 'Row';

  return (
    <div className="flex flex-col w-full h-full overflow-hidden overscroll-none">
      {/* {hasRVFloatingAd && <RVAd />} */}
      {modalContent && <Modal isOpen={true} modalContent={modalContent} />}

      <div className="flex flex-col h-[533px] pb-20">
        <div className="grow">
          {selectedCampaignList.length === 0 ? (
            <div className="flex flex-col gap-[16px] items-center h-full self-center justify-center">
              <img src={NoAd} alt="시계" width={72} height={72} />
              <div className="flex flex-col items-center">
                <span className="text-[#394047] font-semibold text-[18px] leading-[28px]">
                  참여 가능한 광고가 없습니다.
                </span>
                <span className="text-center whitespace-pre text-[14px] leading-[20px] text-[#808C99] font-normal">
                  {`${
                    !isViewMode
                      ? '상단에 다른 미션들을 참여하고 \n리워드를 받아가세요'
                      : showNoAdModal
                      ? '현재 캠페인의 참여 한도가 소진되어 참여할 수 없습니다.'
                      : show501Error
                      ? '더 나은 맞춤형 광고와 리워드를 위해 \n추적을 활성화해 주세요.'
                      : '잠시 후 다시 확인 부탁드릴게요'
                  }`}
                </span>
              </div>
            </div>
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <VariableSizeList
                  ref={listRef}
                  onScroll={handleScroll}
                  height={height}
                  width={width}
                  itemData={
                    selectedCampaignList.length > 5
                      ? selectedCampaignList?.slice(0, 5)
                      : selectedCampaignList
                  }
                  itemSize={(idx) => {
                    return selectedCampaignList?.[idx]?.Key === '958189455' ? 120 : 96;
                  }}
                  itemCount={
                    selectedCampaignList.length > 5
                      ? selectedCampaignList?.slice(0, 5)?.length
                      : selectedCampaignList?.length
                  }
                >
                  {Row}
                </VariableSizeList>
              )}
            </AutoSizer>
          )}
        </div>
        <div className="w-full px-20 flex justify-end items-center">
          <button
            onClick={() => {
              sendMessageToSDK('openOfferwall', null, platform);
            }}
            className="flex gap-4 items-center text-[#728094] font-semibold text-[14px] leading-[22px] h-32"
          >
            <span>더보기</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.11168 3.04781C6.34599 2.81349 6.72589 2.81349 6.96021 3.04781L11.4882 7.57581C11.7225 7.81012 11.7225 8.19002 11.4882 8.42434L6.96021 12.9523C6.72589 13.1867 6.34599 13.1867 6.11168 12.9523C5.87736 12.718 5.87736 12.3381 6.11168 12.1038L10.2154 8.00007L6.11168 3.89633C5.87736 3.66202 5.87736 3.28212 6.11168 3.04781Z"
                fill="#728094"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CampaignList;
