import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  blockedCampaignList,
  isTestState,
  isViewModeState,
  mediaKeyState,
  platformState,
  selectedCampaignState,
} from 'atoms/campaignState';
import { useErrorModal } from 'context';
import { convertCampaignParameter, isToday, sendMessageToSDK } from 'utils';

import { useHandleClickCampaign } from '.';

function filterObjectByCondition(obj, condition) {
  const filteredObject = {};

  // 객체의 모든 키에 대해 순회
  for (const key in obj) {
    // 객체의 속성인지 확인하고, 조건을 만족하는지 확인
    if (Object.hasOwnProperty.call(obj, key) && condition(obj[key])) {
      filteredObject[key] = obj[key]; // 조건을 만족하는 속성을 새 객체에 추가
    }
  }

  return filteredObject;
}

export const useBridgeEventListener = () => {
  const navigate = useNavigate();
  const { openCustomModal, closeModal } = useErrorModal();
  const { checkOrJoinCampaign } = useHandleClickCampaign();
  const selectedCampaign = useRecoilValue(selectedCampaignState);
  const isViewMode = useRecoilValue(isViewModeState);
  const isTest = useRecoilValue(isTestState);
  const platform = useRecoilValue(platformState);
  const mediaKey = useRecoilValue(mediaKeyState);

  const setBlockedCampaignKeys = useSetRecoilState(blockedCampaignList);

  const newObj = localStorage.getItem('blockedCampaignList')
    ? filterObjectByCondition(JSON.parse(localStorage.getItem('blockedCampaignList')), isToday)
    : {};

  const installedCampaignList = localStorage.getItem('installedCampaigns')
    ? JSON.parse(localStorage.getItem('installedCampaigns'))
    : [];

  const [blockedCampaigns, setBlockedCampaigns] = useState(newObj);

  const [installedCampaignKeys, setInstalledCampaignKeys] = useState(installedCampaignList);

  useEffect(() => {
    const nativeResponse = async (event) => {
      console.log(event?.detail?.data, 'nativeEvent');
      const eventResult = event?.detail?.data;

      /**1. 캠페인 완료 */
      if (eventResult.EventName === 'campaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setInstalledCampaignKeys((prev) => {
          if (!prev) {
            localStorage.setItem('installedCampaigns', JSON.stringify([]));
            return [];
          }
          // 새로운 배열로 필터링
          const updatedKeys = prev?.filter((el) => el !== newItem?.toString());
          // 로컬 스토리지에서 해당 항목 제거
          if (newItem) {
            localStorage.setItem('installedCampaigns', JSON.stringify(updatedKeys));
          }
          // 상태 업데이트
          return updatedKeys;
        });
        setBlockedCampaignKeys((prev) => [...prev, { type: 'campaign', key: newItem?.toString() }]);
      }

      /**2. CPM 캠페인 완료  */
      if (eventResult.EventName === 'cpmCampaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setBlockedCampaignKeys((prev) => [...prev, { type: 'content', key: newItem?.toString() }]);
      }

      if (
        eventResult.EventName === 'newsCampaignCompleted' ||
        eventResult.EventName === 'webtoonCampaignCompleted'
      ) {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        const currentDate = new Date();

        const updatedList = { ...blockedCampaigns, [newItem]: currentDate.toISOString() };
        setBlockedCampaigns(updatedList);
        localStorage.setItem('blockedCampaignList', JSON.stringify(updatedList));
      }
      if (eventResult.EventName === 'showDetailPage' && eventResult.Result) {
        if (!selectedCampaign) return;
        if (
          selectedCampaign?.PartiTypeCode === 7 ||
          selectedCampaign?.PartiTypeCode === 23 ||
          selectedCampaign?.PartiTypeCode === 6
        ) {
          const updatedList = selectedCampaign?.Key
            ? [...new Set([...installedCampaignKeys, selectedCampaign?.Key])]
            : installedCampaignKeys;
          setInstalledCampaignKeys(updatedList);
          localStorage.setItem('installedCampaigns', JSON.stringify(updatedList));
        }

        const openURL = isTest
          ? 'https://stage.offerwall.adpopcorn.com'
          : 'https://offerwall.adpopcorn.com';

        if (isViewMode) {
          return sendMessageToSDK(
            'openNewInAppBrowser',
            {
              url: `${openURL}/SDK/detail?auth=${selectedCampaign?.Auth}&isTest=${isTest}&platform=${platform}&mediakey=${mediaKey}`,
            },
            platform
          );
        }

        navigate('/SDK/detail', {
          state: {
            campaign: selectedCampaign,
          },
        });
      }

      if (eventResult.EventName === 'installFail') {
        openCustomModal({
          ResultMessage: eventResult.ResultMessage || '',
          left: { text: '닫기', func: closeModal },
          right: {
            text: '참여하기',
            func: () => {
              sendMessageToSDK(
                'joinCampaign',
                {
                  jsonParameter: `"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`,
                },
                platform
              );
              closeModal();
            },
          },
        });
      }

      if (eventResult.EventName === 'checkCampaignPackageTargetList' && eventResult.Result) {
        checkOrJoinCampaign(selectedCampaign);
      }
    };

    window.addEventListener('NativeEvent', nativeResponse);

    return () => {
      window.removeEventListener('NativeEvent', nativeResponse);
    };
  }, [selectedCampaign]);
};
export default useBridgeEventListener;
