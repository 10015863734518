// import { RVAd } from 'components/RV-ad';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  cashNoteState,
  customState,
  isLandscapeState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
} from 'atoms/campaignState';
import Header from 'components/header';
import TabSwitcher from 'components/tab-switcher';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

import { CashNotePage } from '../cash-note-page';
import { UnauthorizedPage } from '../unauthorized-page';

const mockData = {
  app_key: 2075501244,
  usn: 'izzie-test',
  adid: '77230152-17c2-422a-8901-5a309834387f',
  network: 'wifi',
  carrier: 'unknown',
  model: 'SM-G970N',
  manufacturer: 'samsung',
  os_version: '12',
  sdk_version: '9.0.0a',
  width: 1080,
  height: 2038,
  country: 'KR',
  language: 'ko',
  use_google_account: false,
  google_account_list: [],
  has_one_store: true,
  one_store_account_list: [],
  utc_offset: 9,
  market: '',
  android_opt_out_enabled: false,
  platform: 'android',
  sign_1: false,
  sign_2: false,
  sign_3: false,
  timestamp: 1715153450,
  build_target_version: 31,
};

function useMockData() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const isLocalhost = queryParams.get('env') === 'localhost';

  const setParams = useSetRecoilState(parameterState);
  const setMediaKey = useSetRecoilState(mediaKeyState);

  // LocalStorage에서 객체를 불러오기
  function getMockData() {
    if (!isLocalhost) return null;
    if (window.location.hostname !== 'localhost') return null;

    return mockData;
  }

  useEffect(() => {
    const storedMockData = getMockData();

    if (!storedMockData) return;
    setParams(storedMockData);
    setMediaKey(storedMockData.app_key);
  }, []);
}

export function MainTestPage() {
  const location = useLocation();
  useMockData();

  const [mediaKey, setMediaKey] = useRecoilState(mediaKeyState);
  const [platform, setPlatform] = useRecoilState(platformState);

  const [cashNote, setCashNote] = useRecoilState(cashNoteState);

  const setCustom = useSetRecoilState(customState);
  const setIsViewMode = useSetRecoilState(isViewModeState);
  const setIsLandscape = useSetRecoilState(isLandscapeState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const Agreement = queryParams.get('agreement');
  const Platform = queryParams.get('platform');
  const mediakey = queryParams.get('mediakey');

  const primaryColor = queryParams.get('primaryColor');
  const startTabIndex = queryParams.get('startTabIndex');
  const offerwallTitle = queryParams.get('offerwallTitle');
  const isViewType = queryParams.get('isViewType') === 'true';
  const isLandScape = queryParams.get('isLandscape') === 'true';

  const isAgreed = Agreement === 'true';

  useEffect(() => {
    if (!mediakey) return;
    if (mediakey === '1836641617') {
      const targetUrl = new URL(window.location.href);
      targetUrl.hostname = 'stage.offerwall.adpopcorn.com';

      if (primaryColor) {
        targetUrl.searchParams.set('primaryColor', encodeURIComponent(primaryColor));
      }

      // 한글 타이틀 처리
      if (offerwallTitle) {
        targetUrl.searchParams.set('offerwallTitle', encodeURIComponent(offerwallTitle));
      }

      window.location.href = targetUrl.toString();
    }
    if (parseInt(mediakey) === 42430018 || parseInt(mediakey) === 901924092) {
      setCashNote(true);
    }
    // if (parseInt(mediakey) === 860337341 || parseInt(mediakey) === 74876820) {
    //   return setCustom((prev) => {
    //     return {
    //       offerwallTitle: offerwallTitle || prev.offerwallTitle,
    //       primaryColor: '#FA6EE3',
    //       startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
    //     };
    //   });
    // }
    setIsLandscape(isLandScape);
    setCustom((prev) => {
      return {
        offerwallTitle: offerwallTitle || prev.offerwallTitle,
        primaryColor: primaryColor || prev.primaryColor,
        startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
      };
    });
  }, []);

  useEffect(() => {
    if (!Platform) return;
    setPlatform(Platform || 'android');
    sendMessageToSDK('getParameter', null, Platform || 'android');
  }, [Platform]);

  useEffect(() => {
    if (mediakey) {
      setMediaKey(parseInt(mediakey));
    }
  }, [mediakey]);

  useEffect(() => {
    setIsViewMode(isViewType);
  }, [isViewType]);

  if (!platform) return null;
  if (!isAgreed) return <UnauthorizedPage isViewType={isViewType} isCashNote={cashNote} />;

  // if (mediaKey === 860337341 || mediaKey === 74876820) return <CustomPage />;
  if (cashNote && isViewType) return <CashNotePage />;
  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header mediakey={mediaKey} />
      <TabSwitcher />
    </div>
  );
}

export default MainTestPage;
