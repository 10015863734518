import { Link } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import arrowBack from 'assets/arrow_back.svg';
import help from 'assets/help.svg';
import { platformState } from 'atoms/campaignState';
import { sendMessageToSDK } from 'utils';

export function Header() {
  const platform = useRecoilValue(platformState);
  return (
    <div className="h-[48px] flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20">
      <div
        className="flex flex-row gap-[6px] truncate"
        onClick={() => sendMessageToSDK('closeOfferwall', null, platform)}
        role="presentation"
      >
        <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
      </div>
      <div className="text-bridgeFontSize-16 text-bridgeColor-gray-800 font-[500] truncate">
        오늘의 미션
      </div>
      <Link to={'/cs'} className="flex-shrink-0">
        <img src={help} className="w-[24px] h-[24px]" alt="더보기" role="presentation" />
      </Link>
    </div>
  );
}

export default Header;
