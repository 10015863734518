export const ConvertCampaignFormat = (tabInfoOrig, campaignList) => {
  const { Campaigns, NewsCampaigns, WebtoonCampaigns } = campaignList;

  const TotalCampaignList = [
    ...(Campaigns ?? []),
    // ...(cpmCampaingList ?? []),
    ...(NewsCampaigns ?? []),
    ...(WebtoonCampaigns ?? []),
    // ...(ScheduledCampaigns ?? []),
  ];

  const tabInfo = tabInfoOrig.map((tab) => JSON.parse(JSON.stringify(tab)));

  const TabInfoWithCampaigns = TotalCampaignList?.reduce((tabInfo, campaign) => {
    const PartiTypeCode = campaign?.PartiTypeCode?.toString();

    for (const main of tabInfo) {
      if (main.IncludeParticipationNo.includes('0')) {
        main.campaigns = (main.campaigns ?? []).concat([campaign]);
      }
      if (main.IncludeParticipationNo.includes(PartiTypeCode)) {
        if (main.SubTabInfo.length === 0) {
          main.campaigns = (main.campaigns || []).concat([campaign]);
          return tabInfo;
        }

        const subtabs = main.SubTabInfo;

        for (const sub of subtabs) {
          if (sub.IncludeParticipationNo.includes('0')) {
            sub.campaigns = (sub.campaigns ?? []).concat([campaign]);
          }
          if (sub.IncludeParticipationNo.includes(PartiTypeCode)) {
            sub.campaigns = (sub.campaigns || []).concat([campaign]);

            return tabInfo;
          }
        }
      }
    }

    return tabInfo;
  }, tabInfo);

  const filteredTabInfoWithCampaigns = TabInfoWithCampaigns?.map((campaign) => {
    return {
      ...campaign,
      SubTabInfo: campaign?.SubTabInfo?.filter((subTab) => subTab?.campaigns?.length > 0),
    };
  });

  return filteredTabInfoWithCampaigns;
};
