import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { bridgePageInfoState } from 'atoms/campaignState';
import { useFetchBridgeInfo } from 'hooks2/use-fetch-bridge-info';

import {
  BridgeOverview,
  CarouselSection,
  Header,
  Loading,
  Notification,
  useBridgeEventListener,
} from './default';

export function DefaultPage() {
  const fetchBridgeInfo = useFetchBridgeInfo();
  const bridgePageInfo = useRecoilValue(bridgePageInfoState);
  useBridgeEventListener();

  useEffect(() => {
    // if (bridgePageInfo) return;
    fetchBridgeInfo();
  }, []);

  return (
    <div className="bg-white w-full h-full touch-none overscroll-contain overflow-hidden">
      {!bridgePageInfo && <Loading />}
      <Header />
      <div className="h-[calc(100vh-50px)] overflow-y-scroll overflow-x-hidden">
        <BridgeOverview />
        <CarouselSection />
        <Notification />
      </div>
    </div>
  );
}

export default DefaultPage;
