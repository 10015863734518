import { useEffect } from 'react';

export const BottomSheet = ({ content, onClose, isOpen }) => {
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <div
      className={`z-20 fixed top-0 size-full duration-200 ${
        isOpen ? 'bg-[rgba(0,0,0,0.4)]' : 'opacity-0 pointer-events-none'
      }`}
      role="presentation"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div
        className={`fixed bottom-0 bg-[#ffffff] w-full max-h-[calc(100%-50px)] rounded-t-[8px] transform transition-transform duration-200 ease-in-out ${
          isOpen ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        <div className="w-full h-[16px] rounded-t-[8px] flex justify-center items-center">
          <div className="w-[40px] h-[4px] bg-[#E9ECEF] rounded-full"></div>
        </div>
        {content}
      </div>
    </div>
  );
};
