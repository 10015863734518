import { useCallback, useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';

import { ReactComponent as CheckedCircle } from 'assets/checked_circle.svg';
import close from 'assets/close.png';
import upload from 'assets/upload.png';

export function ImageUploader({ uploadFile, file }) {
  const [, setUploadedImgUrl] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [fileInfo, setFileInfo] = useState({ name: '', size: '' });
  const inputRef = useRef(null);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        'JPEG',
        95,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  // 스크린샷 업로드 핸들러
  const handleChange = useCallback(async (e) => {
    const files = e.target.files;
    const hasImg = files && files.length > 0;

    if (hasImg) {
      // 파일 크기가 4MB 이상인지 확인
      const fileSizeMB = files[0]?.size / (1024 * 1024);

      setFileInfo({ name: files[0]?.name, size: `${fileSizeMB.toFixed(1)} MB` });

      if (fileSizeMB < 4) {
        const src = URL.createObjectURL(files[0]);
        setUploadedImgUrl((prev) => {
          if (prev) {
            URL.revokeObjectURL(prev);
          }
          return src;
        });
        setDisabled(false);
        return uploadFile(files[0]);
      }

      try {
        const resizedFile = await resizeFile(files[0]);
        uploadFile(resizedFile);
        setFileInfo({
          name: resizedFile?.name,
          size: `${(resizedFile.size / (1024 * 1024)).toFixed()} MB`,
        });
        // console.log(resizedFile, files[0], 'resizedFile');

        const src = URL.createObjectURL(resizedFile);

        setUploadedImgUrl((prev) => {
          if (prev) {
            URL.revokeObjectURL(prev);
          }
          return src;
        });

        setDisabled(false);
      } catch (err) {
        console.error('Error resizing the image:', err);
        setDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    const ref = inputRef.current;
    if (ref) {
      ref.addEventListener('change', handleChange);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('change', handleChange);
      }
    };
  }, [handleChange]);

  return (
    <form>
      <input
        id="upload-img"
        type="file"
        ref={inputRef}
        accept=".jpg, .jpeg, .png"
        name="file"
        className="hidden"
      />
      {!file && (
        <label htmlFor="upload-img" className="">
          <div className="text-gray-300 text-[15px] p-[14px] border border-[#F1F2F4] rounded-[8px] flex justify-between">
            문의 내용 관련 스크린샷(4MB 이하)
            <img src={upload} alt="업로드" className="text-gray-700" width={24} height={24} />
          </div>
        </label>
      )}
      {fileInfo.size && (
        <div className="bg-white border border-[#F1F2F4] rounded-[8px] p-[13px] flex flex-col gap-[7px]">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-700 text-[12px] font-medium leading-[16.4px] whitespace-pre-wrap text-ellipsis overflow-hidden">
                {fileInfo.name}
              </span>
              <img
                src={close}
                alt="닫기"
                width={13}
                height={13}
                className="text-gray-700"
                role="presentation"
                onClick={() => {
                  uploadFile(null);
                  setFileInfo({ name: '', size: '' });
                }}
              />
            </div>
            <div className="flex flex-between items-center gap-[7px]">
              <span className="text-[10px] text-gray-400 font-normal leading-[13.12px]">
                {fileInfo.size}
              </span>
              {!disabled && (
                <div className="flex gap-[2px] items-center">
                  <CheckedCircle width={10} height={10} className="fill-[#0E9F6E]" />
                  <span className="text-[10px] font-normal leading-[13.12px] text-gray-700">
                    Complete
                  </span>
                </div>
              )}
            </div>
            {disabled && (
              <div className="relative w-full h-[6px] rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-primary-5 rounded-full z-10"
                  style={{ width: `${40}%` }}
                />
                <div className="absolute inset-0 flex h-full items-center justify-center bg-gray-100" />
              </div>
            )}
          </div>
        </div>
      )}
    </form>
  );
}
