import { createContext, useContext } from 'react';

import useModal from 'hooks/use-modal';

import { Modal } from '../components';

// 모달을 관리하는 Context 생성
const ErrorModalContext = createContext();

// ErrorModalProvider 정의
export function ErrorModalProvider({ children }) {
  const { modalContent, openModal, closeModal, openCustomModal } = useModal();

  return (
    <ErrorModalContext.Provider value={{ modalContent, openModal, closeModal, openCustomModal }}>
      {children}
      {modalContent && <Modal modalContent={modalContent} />}
    </ErrorModalContext.Provider>
  );
}

// 사용자 정의 Hook: 에러 모달에 접근하기 위한 Hook
export function useErrorModal() {
  return useContext(ErrorModalContext);
}
