// import { RVAd } from 'components/RV-ad';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  isLandscapeState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
} from 'atoms/campaignState';
import { sendMessageToSDK } from 'utils';

import { DefaultPage } from './default-page';

import { UnauthorizedPage } from '../unauthorized-page';

const mockData = {
  app_key: '1836641617',
  usn: 'APOfferwallTestUser1',
  adid: 'f2652b17-a94f-43c6-9267-5504ebf6e2aa',
  network: 'wifi',
  carrier: 'unknown',
  model: 'SM-S901N',
  manufacturer: 'samsung',
  os_version: '14',
  sdk_version: '9.1.2a',
  width: 1080,
  height: 2115,
  country: 'KR',
  language: 'ko',
  use_google_account: false,
  google_account_list: [],
  has_one_store: true,
  one_store_account_list: [],
  utc_offset: 9,
  market: '',
  android_opt_out_enabled: false,
  platform: 'android',
  sign_1: false,
  sign_2: false,
  sign_3: false,
  timestamp: 1734492426,
  build_target_version: 34,
  PID: '1836641617Bridge1',
};

function useMockData() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const isLocalhost = queryParams.get('env') === 'localhost';

  const setParams = useSetRecoilState(parameterState);
  const setMediaKey = useSetRecoilState(mediaKeyState);

  // LocalStorage에서 객체를 불러오기
  function getMockData() {
    if (!isLocalhost) return null;
    if (window.location.hostname !== 'localhost') return null;

    return mockData;
  }

  useEffect(() => {
    const storedMockData = getMockData();

    if (!storedMockData) return;
    setParams(storedMockData);
    setMediaKey(storedMockData.app_key);
  }, []);
}

export function BridgeTestPage() {
  const location = useLocation();
  useMockData();

  const [platform, setPlatform] = useRecoilState(platformState);
  const setIsViewMode = useSetRecoilState(isViewModeState);
  const setIsLandscape = useSetRecoilState(isLandscapeState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const Agreement = queryParams.get('agreement');
  const queryPlatform = queryParams.get('platform');
  const isViewType = queryParams.get('isViewType') === 'true';
  const isLandScape = queryParams.get('isLandscape') === 'true';

  const isAgreed = Agreement === 'true';

  useEffect(() => {
    const platform = queryPlatform || 'android';

    setIsLandscape(isLandScape);
    setIsViewMode(isViewType);

    setPlatform(platform);

    sendMessageToSDK('getParameter', null, platform);
  }, []);

  if (!platform) return null;
  if (!isAgreed) return <UnauthorizedPage isViewType={isViewType} />;

  return <DefaultPage />;
}

export default BridgeTestPage;
