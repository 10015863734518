import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  isLandscapeState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
} from 'atoms/campaignState';
import { sendMessageToSDK } from 'utils';

import { DefaultPage } from './default-page';

import { UnauthorizedPage } from '../unauthorized-page';

export function BridgePage() {
  const location = useLocation();
  const parameter = useRecoilValue(parameterState);

  const [platform, setPlatform] = useRecoilState(platformState);

  const setMediaKey = useSetRecoilState(mediaKeyState);
  const setIsViewMode = useSetRecoilState(isViewModeState);
  const setIsLandscape = useSetRecoilState(isLandscapeState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const Agreement = queryParams.get('agreement');
  const queryPlatform = queryParams.get('platform');
  const mediakey = queryParams.get('mediakey');

  // const primaryColor = queryParams.get('primaryColor');
  // const startTabIndex = queryParams.get('startTabIndex');
  // const offerwallTitle = queryParams.get('offerwallTitle');
  const isViewType = queryParams.get('isViewType') === 'true';
  const isLandScape = queryParams.get('isLandscape') === 'true';

  const isAgreed = Agreement === 'true';

  useEffect(() => {
    if (!mediakey) return;
    const platform = queryPlatform || 'android';

    setIsLandscape(isLandScape);
    setIsViewMode(isViewType);
    setMediaKey(parseInt(mediakey));
    setPlatform(platform);

    sendMessageToSDK('getParameter', null, platform);
  }, []);

  if (!platform || !parameter) return null;
  if (!isAgreed) return <UnauthorizedPage isViewType={isViewType} />;

  return <DefaultPage />;
}

export default BridgePage;
