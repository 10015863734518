import { useRecoilValue, useSetRecoilState } from 'recoil';

import { platformState, selectedCampaignState } from 'atoms/campaignState';
import { convertCampaignParameter, sendMessageToSDK } from 'utils';

export const useHandleClickCampaign = () => {
  const setSelectedCampaign = useSetRecoilState(selectedCampaignState);
  const platform = useRecoilValue(platformState);

  const checkInstalledPackageList = (campaign) => {
    setSelectedCampaign(campaign);

    if (campaign?.TargetPackageName?.length || campaign?.DetargetPackageName?.length) {
      return sendMessageToSDK(
        'checkCampaignPackageTargetList',
        {
          targetPackageNameList: campaign?.TargetPackageName || [],
          detargetPackageNameList: campaign?.DetargetPackageName || [],
        },
        platform
      );
    } else checkOrJoinCampaign(campaign);
  };

  const checkOrJoinCampaign = (campaign) => {
    const campaignParams = convertCampaignParameter(campaign);
    const partiNo = campaign?.PartiTypeCode;
    const joinCampaignType = [43, 46, 52, 53];

    if ([...joinCampaignType].includes(partiNo)) {
      return sendMessageToSDK(
        'joinCampaign',
        { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
        platform
      );
    }

    sendMessageToSDK(
      'checkCampaign',
      { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
      platform
    );
  };

  return { checkInstalledPackageList, checkOrJoinCampaign };
};

export default useHandleClickCampaign;
