import { useEffect } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { getTabInfo } from 'apis';
import {
  customState,
  isTestState,
  mediaKeyState,
  parameterState,
  selectedTabState,
  tabListState,
} from 'atoms/campaignState';
import { useErrorModal } from 'context';

export function TabSwitcherUnAuthorized() {
  const { openModal } = useErrorModal();

  const mediaKey = useRecoilValue(mediaKeyState);
  const isTest = useRecoilValue(isTestState);
  const parameter = useRecoilValue(parameterState);
  const custom = useRecoilValue(customState);

  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [tabList, setTabList] = useRecoilState(tabListState);

  useEffect(() => {
    async function fetch() {
      try {
        const tabInfoData = await getTabInfo(mediaKey, parameter);

        const tabInfo = tabInfoData?.MediaTabInfo || [];

        setTabList(tabInfo);

        const initialState = tabInfo?.reduce(
          (acc, cur, index) => {
            if (custom.startTabIndex === index) {
              acc.selectedTabId = cur.TypeNo;
            }
            acc.selectedSubTabData[cur.TypeNo] = cur.SubTabInfo?.length
              ? cur.SubTabInfo.find((subTab) => subTab.IncludeParticipationNo.includes('0')).TypeNo
              : null;

            return acc;
          },
          { selectedTabId: 0, selectedSubTabData: {} }
        );

        if (initialState) {
          setSelectedTab(initialState);
        }
      } catch (err) {
        openModal(err.message || '');
      }
    }
    if (!mediaKey || !parameter) return;
    if (tabList?.length !== 0) return;
    fetch();
  }, [mediaKey, parameter]);

  // if (isLoading) return null;
  if (tabList.length === 0) return null;

  const { selectedTabId } = selectedTab;

  return (
    <div className="w-full h-full flex flex-col relative">
      {isTest && (
        <span
          style={{
            color: custom.primaryColor,
          }}
          className={`absolute top-[calc(50%)] left-[calc(50%-30px)] z-30`}
        >
          Test Mode
        </span>
      )}

      <div className="sticky top-0 bg-white z-10">
        {/* <div className="w-full h-78 pt-40 pb-12">
          <div className="flex justify-between items-center px-20">
            <span className="font-bold text-[19px] leading-[26px] text-[#0C1120]">
              오늘 받을 수 있는 포인트
            </span>
            <span className="text-[#0D63E6] font-semibold text-[16px] leading-[22px]">{`총 ${numberWithCommas(
              totalReward
            )}${currencyString}`}</span>
          </div>
        </div> */}
        <div className="px-20 grid grid-flow-col gap-16 break-keep border-b border-[#F4F7F9] overflow-x-scroll">
          {tabList.map((tab) => (
            <div
              key={tab.TypeNo}
              role="presentation"
              onClick={() => {
                setSelectedTab((prev) => {
                  return { ...prev, selectedTabId: tab.TypeNo };
                });
              }}
              className={`py-14 text-16 px-10 m-auto font-semibold flex justify-center items-center whitespace-nowrap ${
                tab.TypeNo === selectedTabId
                  ? 'text-[#0C1120] border-b-[2px] border-[#0C1120]'
                  : 'text-[#728094] border-b-[2px] border-transparent'
              }`}
            >
              <span className="whitespace-nowrap">{tab.Name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="h-full w-full bg-white flex justify-center items-center flex-col gap-4">
        <span className="text-[#394047] font-semibold leading-[28px] text-[18px]">
          개인정보 이용 및 수집 동의가 필요해요
        </span>
        <span className="text-[#808C99] font-regular leading-[20px] text-[14px]">{`새로고침 후 개인정보 수집 동의에 "동의"해 주세요.`}</span>
      </div>
    </div>
  );
}

export default TabSwitcherUnAuthorized;
