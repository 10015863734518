import { Link } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import arrowBack from 'assets/arrow_back.svg';
import cashNoteHelp from 'assets/cashnote-help.png';
import circleInfo from 'assets/circle-info.png';
import help from 'assets/help.svg';
import { customState, isViewModeState, platformState } from 'atoms/campaignState';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

// const CSModalButton = lazy(() => import('components/cs-modal-button'));

export function Header({ title, _onClick, mediakey }) {
  const isDetailPage = title || _onClick;

  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);
  const isViewType = useRecoilValue(isViewModeState);

  const onClick = () => {
    sendMessageToSDK('closeOfferwall', null, platform);
  };

  const isCashNote = mediakey === 42430018 || mediakey === 901924092;
  const isZum = mediakey === 55849288 || mediakey === 824622555;

  if (isZum && !isDetailPage)
    return (
      <div className="h-[48px] flex w-[100vw] items-center justify-between px-[15px] py-[10px] bg-white z-20 text-gray-900">
        <div className="w-20" />
        <div className="text-[18px] leading-[25.2px] truncate font-[600] self-center">
          {title || '혜택 zum'}
        </div>
        <Link to={'/cs'} className="flex-shrink-0">
          <img
            className="w-[24px] h-[24px]"
            width={24}
            height={24}
            src={circleInfo}
            alt="더보기"
            role="presentation"
          />
        </Link>
      </div>
    );

  return (
    <div className="h-[44px] flex w-[100vw] overflow-hidden items-center justify-between px-[15px] py-[10px] bg-white z-20 tracking-[-0.5px]">
      <div
        className="flex flex-row gap-[6px] truncate"
        onClick={(e) => {
          if (isViewType) return;
          if (_onClick && typeof _onClick === 'function') {
            return _onClick();
          }
          onClick();
        }}
        role="presentation"
      >
        {(!isViewType || isDetailPage) && (
          <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
        )}
        <div className="text-[18px] leading-[25.2px] text-gray-900 font-[600] truncate">
          {isCashNote ? '' : title || custom.offerwallTitle}
        </div>
      </div>

      <Link to={'/cs'} className="flex-shrink-0">
        {isCashNote ? (
          <div className="flex items-center gap-4 text-[#44546F] font-semibold text-[14px] leading-[22px] whitespace-pre">
            <img
              className="w-[20px] h-[20px]"
              width={20}
              height={20}
              src={cashNoteHelp}
              alt="더보기"
              role="presentation"
            />
            <span>고객센터</span>
          </div>
        ) : (
          <img
            src={isZum ? circleInfo : help}
            className="w-[24px] h-[24px]"
            alt="더보기"
            role="presentation"
          />
        )}
      </Link>
    </div>
  );
}

export default Header;
