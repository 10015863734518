import { useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getTabInfo } from 'apis';
import {
  currencyState,
  customState,
  isTestState,
  mediaKeyState,
  parameterState,
  platformState,
  selectedCampaignState,
  selectedTabState,
  tabListState,
  totalRewardState,
} from 'atoms/campaignState';
import { useErrorModal } from 'context';
import { convertCampaignParameter, numberWithCommas } from 'utils';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

import { CampaignList } from './campaign-list';

export function TabSwitcher() {
  const { openModal } = useErrorModal();
  const mediaKey = useRecoilValue(mediaKeyState);
  const isTest = useRecoilValue(isTestState);
  const platform = useRecoilValue(platformState);
  const currency = useRecoilValue(currencyState);
  const parameter = useRecoilValue(parameterState);
  const custom = useRecoilValue(customState);
  const totalReward = useRecoilValue(totalRewardState);

  const setSelectedCampaign = useSetRecoilState(selectedCampaignState);
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [tabList, setTabList] = useRecoilState(tabListState);

  const currencyString = currency || 'P';

  useEffect(() => {
    const selectedTabId = selectedTab?.selectedTabId?.toString() || '';
    if (!selectedTabId) return;

    sendMessageToSDK(
      'logging',
      {
        eventName: 'impression_offerwall_tab',
        eventParam: selectedTabId,
      },
      platform
    );
  }, [selectedTab?.selectedTabId]);

  const checkInstalledPackageList = (campaign) => {
    setSelectedCampaign(campaign);

    if (campaign?.TargetPackageName?.length || campaign?.DetargetPackageName?.length) {
      return sendMessageToSDK(
        'checkCampaignPackageTargetList',
        {
          targetPackageNameList: campaign?.TargetPackageName || [],
          detargetPackageNameList: campaign?.DetargetPackageName || [],
        },
        platform
      );
    } else checkOrJoinCampaign(campaign);
  };

  const checkOrJoinCampaign = (campaign) => {
    const campaignParams = convertCampaignParameter(campaign);
    const partiNo = campaign?.PartiTypeCode;
    const joinCampaignType = [43, 46, 52, 53];

    if ([...joinCampaignType].includes(partiNo)) {
      return sendMessageToSDK(
        'joinCampaign',
        { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
        platform
      );
    }

    sendMessageToSDK(
      'checkCampaign',
      { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
      platform
    );
  };

  useEffect(() => {
    async function fetch() {
      try {
        const tabInfoData = await getTabInfo(mediaKey, parameter);

        const tabInfo = tabInfoData?.MediaTabInfo || [];

        setTabList(tabInfo);

        const initialState = tabInfo?.reduce(
          (acc, cur, index) => {
            if (custom.startTabIndex === index) {
              acc.selectedTabId = cur.TypeNo;
            }
            acc.selectedSubTabData[cur.TypeNo] = cur.SubTabInfo?.length
              ? cur.SubTabInfo.find((subTab) => subTab.IncludeParticipationNo.includes('0')).TypeNo
              : null;

            return acc;
          },
          { selectedTabId: 0, selectedSubTabData: {} }
        );

        if (initialState) {
          setSelectedTab(initialState);
        }
      } catch (err) {
        openModal(err.message || '');
      }
    }
    if (!mediaKey || !parameter) return;
    if (tabList?.length !== 0) return;
    fetch();
  }, [mediaKey, parameter]);

  // if (isLoading) return null;
  if (tabList.length === 0) return null;

  const { selectedTabId } = selectedTab;

  // 현재 날짜가 주어진 날짜와 동일한지 확인하는 함수

  return (
    <div className="w-full h-fit flex flex-col relative">
      {isTest && (
        <span
          style={{
            color: custom.primaryColor,
          }}
          className={`absolute top-[calc(50%)] left-[calc(50%-30px)] z-30`}
        >
          Test Mode
        </span>
      )}

      <div className="sticky top-0 bg-white z-10">
        <div className="flex flex-col justify-start pb-12 px-20 pt-20 gap-5">
          <span className="font-semibold text-[16px] leading-[22px] text-[#0C1120]">
            오늘 적립할 수 있는 포인트
          </span>
          <span className="text-[#2D91FF] font-bold text-[24px] leading-[32px]">{`총 ${numberWithCommas(
            totalReward
          )}${currencyString}`}</span>
        </div>

        <div className="px-20 grid grid-flow-col gap-16 break-keep border-b border-[#F4F7F9] overflow-x-scroll no-scrollbar">
          {tabList.map((tab) => (
            <div
              key={tab.TypeNo}
              role="presentation"
              onClick={() => {
                setSelectedTab((prev) => {
                  return { ...prev, selectedTabId: tab.TypeNo };
                });
              }}
              className={`py-10 text-16 leading-[26px] px-10 m-auto  flex justify-center items-center whitespace-nowrap ${
                tab.TypeNo === selectedTabId
                  ? 'text-[#0C1120] border-b-[2px] font-semibold border-[#0C1120]'
                  : 'text-[#728094] border-b-[2px] font-normal border-transparent'
              }`}
            >
              <span className="whitespace-nowrap">{tab.Name}</span>
            </div>
          ))}
        </div>

        {/* {selectedTabInfo?.SubTabInfo?.length > 0 && (
          <div className="flex gap-8 px-20 pt-20 pb-24 text-[13px] whitespace-nowrap w-full overflow-x-auto">
            {selectedTabInfo?.SubTabInfo?.map((subTab) => (
              <div
                className={`py-6 px-12 rounded-[16px] ${
                  subTab.TypeNo === selectedSubTabData[selectedTabId]
                    ? 'text-white bg-[#222B3E] font-semibold'
                    : 'text-[#0C1120] border border-[#E8EDF2] font-semibold bg-white'
                }`}
                key={subTab.TypeNo}
                role="presentation"
                onClick={() => {
                  setSelectedTab((prev) => {
                    return {
                      ...prev,
                      selectedSubTabData: {
                        ...prev.selectedSubTabData,
                        [prev.selectedTabId]: subTab.TypeNo,
                      },
                    };
                  });
                }}
              >
                {subTab.Name}
              </div>
            ))}
          </div>
        )} */}
      </div>

      {tabList.length > 0 && (
        <CampaignList
          checkInstalledPackageList={checkInstalledPackageList}
          checkOrJoinCampaign={checkOrJoinCampaign}
        />
      )}
    </div>
  );
}

export default TabSwitcher;
