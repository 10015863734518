export const Notification = () => {
  return (
    <div className="px-20 py-24 bg-[#f7f7f7] flex-col justify-start items-start gap-10 inline-flex text-bridgeFontSize-1">
      <div className="flex-col justify-start items-start gap-4 flex">
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            {`'광고 차단' 확장 프로그램을 사용하면 광고 페이지가 보이지 않고, 포인트도 적립되지 않아요.`}
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] ₩h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            {` 광고 차단 기능 해제는 [삼성 브라우저 -> 하단 우측 '햄버거 탭' -> 광고 차단 기능
            해제]에서 확인할 수 있어요.`}
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            {`캠페인 미션 완료 시 리워드가 지급되며, 기존 이벤트 참여 이력이 있는 경우 리워드가
            지급되지 않아요.`}
          </div>
        </div>
        <div className="justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="w-[309px] text-[#adadad] text-[13px] font-normal">
            보안폴더를 통해 참여할 경우, 리워드가 지급되지 않아요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            네트워크 장애가 발생한 경우, 리워드 지급이 지연될 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            캠페인 참여를 통해 지급된 리워드는 재전환 혹은 전환을 취소할 수 없고, 유효 기간 동안
            미사용한 리워드는 자동 소멸될 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            리워드의 종류와 지급 수량은 회사의 정책에 따라 변경될 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            WI-FI 환경이 아닌 경우, 데이터 이용료가 발생할 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            본 캠페인은 사정에 따라 사전 공지 없이 내용이 수정되거나 종료될 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            본 캠페인과 관련 없는 단순 홍보나 비방, 포인트 획득 알림 등의 내용을 해당 앱 리뷰에
            등록할 경우, 이용 중인 서비스에 제한을 받을 수 있어요.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            본 캠페인은 (주)애드팝콘이 제공합니다.
          </div>
        </div>
        <div className="w-full justify-start items-start gap-8 inline-flex">
          <div className="w-[3px] h-20 justify-center items-center gap-10 flex">
            <div className="w-[3px] h-[3px] bg-[#adadad] rounded-full"></div>
          </div>
          <div className="grow shrink basis-0 text-[#adadad] text-[13px] font-normal">
            캠페인 문의는 상단의 ‘고객센터’를 통해 문의가 가능해요.
          </div>
        </div>
      </div>
    </div>
  );
};
