import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { addCSRequest } from 'apis/cs';
import { ReactComponent as CheckedBox } from 'assets/checked_box.svg';
import Loading from 'assets/ico-loading.gif';
import { customState } from 'atoms/campaignState';
import { Modal } from 'components';
import { ImageUploader } from 'components/image-uploader';

const initialState = {
  customer_name: '',
  customer_phone: '',
  customer_email: '',
  message: '',
};

export function CSRequestForm() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search + location.hash);

  const isTest = queryParams.get('isTest') === 'true';
  const title = queryParams.get('campaign') || encodeURIComponent('선택한 캠페인');

  const auth = queryParams.get('auth');
  const primaryColor = queryParams.get('primaryColor');

  const [loading, setLoading] = useState(false);

  const custom = useRecoilValue(customState);

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState(initialState);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const isReady =
    !!formData.customer_email && // 이메일 필드가 존재하고
    !!formData.customer_name && // 이름 필드가 존재하고
    !!formData.customer_phone && // 전화번호 필드가 존재하고
    !!formData.message && // 메시지 필드가 존재하고
    !!auth && // 캠페인이 선택되어 있고
    isAgreed && // 약관 동의가 되어 있고
    !isSubmitted &&
    formData.customer_name.match(/^[a-zA-Z가-힣]+$/) && // 이름이 올바른 형식이고
    formData.customer_phone.match(/^(?:\d{3}-?)?\d{3,4}-?\d{4}$/) && // 전화번호가 올바른 형식이고
    formData.customer_email.match(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/); // 이메일이 올바른 형식일 때

  const onChangeRequestData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitV2 = async () => {
    setLoading(true);

    const form = new FormData();

    form.append('customer_email', formData.customer_email);
    form.append('customer_phone', formData.customer_phone);
    form.append('message', formData.message);

    form.append('customer_name', formData.customer_name);
    form.append('file', file);
    form.append('auth', auth);
    if (!isReady) return;

    try {
      const res = await addCSRequest(form, isTest);
      setShowModal(res);
      setFormData(initialState);
      setIsSubmitted(true);
    } catch (e) {
      setShowModal({ ResultMsg: e.message, Result: false });
      // openModal(e.message || '');
    } finally {
      setLoading(false); // 로딩 끝
    }
  };

  return (
    <div className="px-[16px] mt-[32px]">
      {showModal && (
        <Modal
          pos="fixed"
          isOpen={showModal?.ResultMsg}
          onClose={() => setShowModal(false)}
          modalContent={{
            ResultMessage: showModal.Result
              ? '문의가 정상적으로 접수되었습니다.\n 브라우저를 종료한 후 다시 오퍼월로 돌아가주세요.'
              : showModal?.ResultMsg,
            center: {
              text: '확인',
              func: () => {
                setShowModal(false);
              },
            },
          }}
        />
      )}

      <div className="p-[14px] flex items-center justify-start bg-[#EBECEF] rounded-[8px] h-[50px] w-full text-[#505963] font-regular text-[15px] leading-[21.9px]">
        {title && decodeURIComponent(title)}
      </div>

      <div className="flex flex-col">
        <div className="pt-[8px] text-gray-500 text-[13px] leading-[18.2px] pb-[16px]">
          캠페인을 끝까지 완료했나요? 그래도 적립이 되지 않았다면, 아래 내용으로 문의를
          접수해주세요.
        </div>
        <div className="flex flex-col gap-[11px]">
          <div className="flex flex-col gap-[2px]">
            <input
              name="customer_name"
              placeholder="이름 (필수)"
              className="placeholder-gray-300 text-[15px] p-[14px] border border-gray-100 rounded-[8px]"
              onChange={onChangeRequestData}
              value={formData.customer_name}
            />
            <div className="text-[9px] text-[#FF0000]">
              {formData.customer_name &&
                !formData.customer_name.match(/^[a-zA-Z가-힣]+$/) &&
                '이름은 영문 또는 한글로 입력하세요.'}
            </div>
          </div>
          <div className="flex flex-col gap-[2px]">
            <input
              name="customer_phone"
              placeholder="핸드폰 번호 (필수)"
              className="placeholder-gray-300 text-[15px] p-[14px] border border-gray-100 rounded-[8px]"
              onChange={onChangeRequestData}
              value={formData.customer_phone}
            />
            <div className="text-[9px] text-[#FF0000]">
              {formData.customer_phone &&
                !formData.customer_phone.match(/^(?:\d{3}-?)?\d{3,4}-?\d{4}$/) &&
                '올바른 핸드폰 번호 형식을 입력하세요.'}
            </div>
          </div>
          <div className="flex flex-col gap-[2px]">
            <input
              placeholder="답변 받을 이메일 (필수)"
              name="customer_email"
              className="placeholder-gray-300 text-[15px] p-[14px] border border-gray-100 rounded-[8px]"
              onChange={onChangeRequestData}
              value={formData.customer_email}
            />
            <div className="text-[9px] text-[#FF0000]">
              {formData.customer_email &&
                !formData.customer_email.match(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/) &&
                '올바른 이메일 주소 형식을 입력하세요.'}
            </div>
          </div>
          <ImageUploader uploadFile={(file) => setFile(file)} file={file} />
          <textarea
            name="message"
            placeholder="문의 내용 (필수)"
            className="h-[120px] placeholder-gray-300 text-[15px] p-[14px] border border-gray-100 rounded-[8px]"
            onChange={onChangeRequestData}
            value={formData.message}
          />
        </div>
        <div className="pt-[8px] text-gray-500 text-[13px] leading-[18.2px] pb-[16px]">
          문의에 대한 답변은 영업일 기준 2~3일 이내에 입력하신 이메일로 보내드립니다.
        </div>
        <div className="flex flex-col rounded-[8px] justify-start border border-gray-100">
          <div className="flex p-[14px] gap-[8px] border-b border-b-gray-100">
            <CheckedBox
              onClick={() => setIsAgreed(!isAgreed)}
              width={20}
              height={20}
              fill={isAgreed ? primaryColor || custom.primaryColor : '#D9D9D9'}
            />
            <div className="text-[15px] leading-[21px] text-gray-900">
              개인정보 이용 및 수집에 동의합니다.
            </div>
          </div>
          <div className="flex flex-col gap-[4px] text-gray-500 text-[13px] leading-[18.2px] p-[14px]">
            <div> 당사는 다음과 같은 개인 정보를 수집하고 있습니다.</div>
            <ul className="list-inside list-disc flex flex-col gap-[4px]">
              <li>
                <span className="relative left-[-5px]">수집 항목 : 이름, 핸드폰 번호, 이메일</span>
              </li>
              <li>
                <span className="relative left-[-5px]">수집 목적 : 문의 내역 확인과 답변 처리</span>
              </li>
              <li>
                <span className="relative left-[-5px]">활용 기간: 3년 보관 후 파기 </span>
              </li>
            </ul>
            <div>고객님은 동의를 거부할 수 있으며, 동의 거부 시 고객센터를 이용할 수 없습니다.</div>
          </div>
        </div>

        <div className="relative my-[10px]">
          <button
            onClick={handleSubmitV2}
            disabled={!isReady || loading}
            style={{
              backgroundColor: isReady && (primaryColor || custom.primaryColor),
            }}
            className={`${
              !isReady && 'bg-gray-300'
            } text-white text-[16px] leading-[22.4px] w-full h-[54px] flex justify-center items-center rounded-[8px]`}
          >
            {loading ? (
              <div className="absolute inset-0 flex items-center justify-center h-full">
                <img src={Loading} alt="Loading" className="w-[24px] h-[24px]" />
              </div>
            ) : isSubmitted ? (
              '오퍼월로 돌아가주세요.'
            ) : (
              '문의하기'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
