import { useEffect } from 'react';

export const Loading = () => {
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <div className="w-full h-full justify-center items-center flex bg-black opacity-60 fixed z-30  overflow-hidden">
      <div className="w-[70px] h-[70px] justify-center items-center inline-flex animate-spin">
        <div className="w-[70px] h-[70px] relative">
          <div className="w-[5.83px] h-[14.58px] left-[32.08px] top-0 absolute bg-white rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-0 top-[37.92px] absolute origin-top-left -rotate-90 bg-white/70 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[37.92px] top-[70px] absolute origin-top-left rotate-180 bg-white/50 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[70px] top-[32.08px] absolute origin-top-left rotate-90 bg-white/30 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[8.19px] top-[12.31px] absolute origin-top-left -rotate-45 bg-white/90 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[12.31px] top-[61.81px] absolute origin-top-left rotate-[-135deg] bg-white/60 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[61.81px] top-[57.69px] absolute origin-top-left rotate-[135deg] bg-white/40 rounded-[14.58px]" />
          <div className="w-[5.83px] h-[14.58px] left-[57.69px] top-[8.19px] absolute origin-top-left rotate-45 bg-white/20 rounded-[14.58px]" />
        </div>
      </div>
    </div>
  );
};
