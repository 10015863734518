import { useLocation, useNavigate } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getBridgeCampaignInfo } from 'apis';
import {
  bridgePageInfoState,
  currencyState,
  isTestState,
  NoAdModalState,
  parameterState,
  platformState,
  totalRewardState,
} from 'atoms/campaignState';
import { useErrorModal } from 'context';
import { sendMessageToSDK } from 'utils';

export function useFetchBridgeInfo() {
  const location = useLocation();
  const platform = useRecoilValue(platformState);
  const parameter = useRecoilValue(parameterState);
  const setIsTest = useSetRecoilState(isTestState);
  const setTotalReward = useSetRecoilState(totalRewardState);
  const setBridgePageInfo = useSetRecoilState(bridgePageInfoState);
  const setCurrency = useSetRecoilState(currencyState);
  const setShowNodAdModal = useSetRecoilState(NoAdModalState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const { openCustomModal, openModal, closeModal } = useErrorModal();
  const navigate = useNavigate();

  const PID = queryParams.get('PID');

  const fetchBridgeInfo = async () => {
    if (!PID) return;
    try {
      const bridgeInfo = await getBridgeCampaignInfo({ ...parameter, PID });

      const totalReward = bridgeInfo?.TotalRewardQuantity;
      const currency = bridgeInfo?.RewardCurrency;
      const isTest = bridgeInfo?.IsTest;

      const bridgeType = bridgeInfo?.BridgeType || 0;
      const bridgeStyleType = bridgeInfo?.StyleType || 0;
      const bridgeSections = bridgeInfo?.Sections || [];

      setBridgePageInfo({
        bridgeType,
        bridgeStyleType,
        bridgeSections,
      });

      sendMessageToSDK('onOfferwallLoadSuccess', null, platform);

      setTotalReward(totalReward);
      sendMessageToSDK('setTotalReward', { totalReward: totalReward }, platform);

      setCurrency(currency);
      sendMessageToSDK('setRewardCurrency', { currency: currency }, platform);

      setIsTest(isTest);
      sendMessageToSDK('isTestMode', { enable: isTest }, platform);
    } catch (e) {
      setBridgePageInfo([]);
      sendMessageToSDK('onOfferwallLoadFailed', null, platform);

      if (e?.code === 501) {
        return openModal(
          "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
        );
      } else if (e?.code === 980) {
        setShowNodAdModal(true);
        return openCustomModal({
          ResultMessage:
            '참여 가능한 광고가 없습니다. 자세한 안내를 보시려면 아래 이동 버튼을 눌러주세요.',
          left: {
            text: '닫기',
            func: () => {
              sendMessageToSDK('closeOfferwall', null, platform);
            },
          },
          right: {
            text: '이동',
            func: () => {
              closeModal();
              navigate('/cs');
            },
          },
        });
      } else openModal(e.message || '');
    }
  };

  return fetchBridgeInfo;
}
