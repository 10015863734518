import { memo, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ReactComponent as CheckedCircle } from 'assets/checked_circle.svg';
import { completedRcpcList, currencyState, customState, platformState } from 'atoms/campaignState';
import { convertCampaignParameter } from 'utils/convert-campaign-parameter';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

export const RcpcAd = memo(
  ({ campaign }) => {
    const { Tag, ScriptId, IsAvailable } = campaign;
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [externalIframeHtml, setExternalIframeHtml] = useState('');

    const platform = useRecoilValue(platformState);
    const currency = useRecoilValue(currencyState);
    const custom = useRecoilValue(customState);
    const rcpcCampaign = useRecoilValue(completedRcpcList);

    const currencyString = currency || 'P';

    const isCompleted = rcpcCampaign?.includes(ScriptId);

    const handleClickRCPC = () => {
      if (isCompleted) return;
      if (!IsAvailable) return;
      const campaignParams = convertCampaignParameter(campaign);
      sendMessageToSDK(
        'completeCampaign',
        { jsonParameter: `"${JSON.stringify(campaignParams)}"` },
        platform
      );
    };

    useEffect(() => {
      // 외부에서 HTML 문자열을 받아올 로직
      // 이 예시에서는 외부에서 받아온 HTML 문자열을 직접 지정합니다.
      const iframeTag = Tag;
      const externalHtml = `
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              body {
                overflow:hidden;
                margin: 0;
                padding: 0;
              }
            </style>
          </head>
          <body>
            ${iframeTag}
          </body>
        </html>
      `;
      setExternalIframeHtml(externalHtml);
    }, []);

    useEffect(() => {
      const handleIframeLoad = () => {
        setIframeLoaded(true);
        window.focus();
      };

      const iframe = document.getElementById('iframe-example1');
      iframe.addEventListener('load', handleIframeLoad, { once: true });

      return () => {
        iframe.removeEventListener('load', handleIframeLoad);
      };
    }, []);

    const onClickIframeExample = () => {
      const activeElement = document.activeElement;
      if (activeElement.tagName !== 'IFRAME') return;

      if (activeElement.dataset.loaded === 'true') {
        const activeIframeId = activeElement.id;

        if (activeIframeId === 'iframe-example1') {
          handleClickRCPC();
          // IFRAME_ID에 해당하는 콜백 함수 실행
        }
        window.focus();
      }
    };

    useEffect(() => {
      window.addEventListener('blur', onClickIframeExample);

      return () => {
        window.removeEventListener('blur', onClickIframeExample);
      };
    }, []);

    return (
      <div className="h-full flex justify-center items-center relative">
        <iframe
          id="iframe-example1"
          title="example-iframe"
          width={320}
          height={100}
          data-loaded={iframeLoaded}
          style={{ border: 'none' }}
          srcDoc={externalIframeHtml}
        />
        {isCompleted || !IsAvailable ? (
          // <img className="absolute top-0 right-0" alt="체크 아이콘" src={checked_circle} />
          <CheckedCircle
            width={20}
            height={20}
            className="absolute top-0 right-0"
            fill={custom.primaryColor}
          />
        ) : (
          <div
            style={{
              backgroundColor: custom.primaryColor,
            }}
            className={`absolute top-0 right-0 rounded-full px-[8px] py-[1px] text-white text-[12px] leading-[16px] font-[600]`}
          >
            {campaign?.Reward}
            {currencyString}
          </div>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
);

RcpcAd.displayName = 'RcpcAd';

export default RcpcAd;
